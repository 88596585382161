import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as styles from "./styles.module.scss";
import BottomSection from "../BottomSection";

export default function MainBanner() {
  const [activeTab, setActiveTab] = useState("Free trial");

  const tabContent = {
    "Free trial": {
      title: "Get a Hands-on Experience",
      desc: "In our All-in-One Lite version you can try online video, creating cool Show Rooms, CVs, meeting material and much more. We also have videos and more to get you going",
      btnText: "Sign Up",
      linkTo:
        "https://accounts.masterwizr.com/campaign/campaign_65958a56afc9cb190d926401",
      videoSrc:
        "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1725873978/Templates/FreeTrial_Final_vuzzkc.mp4",
      backgroundImage:
        "url('https://res.cloudinary.com/dsg2ktuqk/image/upload/v1725009026/Group_1261153064_qbeboi.jpg')",
    },
    Newsletter: {
      title: "Join Our Creative Community",
      desc: "Stay updated with our latest news and offers by subscribing to our newsletter.",
      btnText: "Learn More",
      videoSrc:
        "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1725908246/Templates/Newsletter_Final_kyc7yf.mp4",
      backgroundImage:
        "url('https://res.cloudinary.com/dsg2ktuqk/image/upload/v1725023533/Group_1261153062_2_dg3y6p.jpg')",
      linkTo: "https://wizr.us/digital-assets",
    },
    "Custom Startup": {
      title: "Build Your Custom Startup",
      desc: "We help you start your dream project with custom solutions tailored to your needs.",
      btnText: "Learn more",
      videoSrc:
        "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1675548029/mwvideos/652997448164277253master_video_1_-_dark_mode_final_2_pcoqxx.mp4",
      backgroundImage:
        "url('https://res.cloudinary.com/dsg2ktuqk/image/upload/v1725013177/Group_1261153063_1_p0rbzl.jpg')",
      linkTo: "https://wizr.us/demo",
    },
    // Products: {
    //   title: "Innovative Products",
    //   desc: "Discover our range of products designed to simplify your life and work.",
    //   btnText: "Shop Now",
    //   videoSrc: "https://res.cloudinary.com/dsg2ktuqk/video/upload/v1675552016/mwvideos/vdscreen342_dmgamd.mp4",
    //   backgroundImage: "url('https://example.com/services-bg.jpg')"
    // }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.mainBanner}
        style={{ backgroundImage: tabContent[activeTab].backgroundImage }}
      >
        <div className={styles.text}>
          <h1 className={styles.title}>
            Join or ask us today <br />{" "}
            <span className={styles.subTitle}>3 weeks FREE</span>
          </h1>
          <div className={styles.tabs}>
            {Object.keys(tabContent).map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={activeTab === tab ? styles.active : ""}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>
      <TransitionGroup>
        <CSSTransition
          key={activeTab}
          timeout={500}
          classNames={{
            enter: styles.enter,
            enterActive: styles.enterActive,
            exit: styles.exit,
            exitActive: styles.exitActive,
          }}
        >
          <BottomSection
            title={tabContent[activeTab].title}
            desc={tabContent[activeTab].desc}
            btnText={tabContent[activeTab].btnText}
            videoSrc={tabContent[activeTab].videoSrc}
            linkTo={tabContent[activeTab].linkTo}
          />
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}
