import React from "react";
import * as styles from "./styles.module.scss";

export default function CampaignSection({ bgColor, imageSrc, title, ...rest }) {
  return (
    <div className={styles.campaign} style={{ backgroundColor: bgColor }}>
      <div className={styles.content}>
        <img className={styles.image} src={imageSrc} alt="campaign" />
        <div className={styles.text} style={{ color: rest?.color }}>
          <span className={styles.topText}>{rest?.topText}</span>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.desc}>{rest?.desc}</p>
          <button
            onClick={() => window.open(`${rest?.toLink}`, "_blank")}
            className={styles.btn}
          >
            {rest?.btnText}
          </button>
        </div>
      </div>
    </div>
  );
}
