// extracted by mini-css-extract-plugin
export var active = "styles-module--active--2279c";
export var enter = "styles-module--enter--400e7";
export var enterActive = "styles-module--enterActive--05332";
export var exit = "styles-module--exit--dc77a";
export var exitActive = "styles-module--exitActive--31cd8";
export var mainBanner = "styles-module--mainBanner--b8eae";
export var subTitle = "styles-module--subTitle--3e597";
export var tabs = "styles-module--tabs--33906";
export var text = "styles-module--text--1330f";
export var title = "styles-module--title--c0615";
export var wrapper = "styles-module--wrapper--a398b";