import React from 'react';
import * as styles from "./styles.module.scss";

export default function BottomSection({title, videoSrc, ...rest}) {
  return (
    <div className={styles.bottomSection}>
      <div className={styles.text}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.desc}>{rest?.desc}</p>
        <button onClick={() => window.open(`${rest?.linkTo}`, "_blank")}>{rest?.btnText}</button>
      </div>
      <video src={videoSrc} loop autoPlay muted />
    </div>
  )
}
